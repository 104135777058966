<template>
    <div class="order-add">
        <invoice-page order-type="invoice" :invoice-creation="$route.name !== 'invoices-duplicate'" :invoice-duplication="$route.name === 'invoices-duplicate'"></invoice-page>
    </div>
</template>

<script>
    import InvoicePage from "../../../components/invoice/InvoicePage";

    export default {
        name: "AddOrder",
        components: {InvoicePage}
    }
</script>

<style scoped>

</style>
